import React from "react";
import { Outlet } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { AppToolbar } from "./AppToolbar";
import AppBottomNavigation from "./AppBottomNavigation";
import {ToolBarActionsProvider} from "./common/ToolBarActionsProvider";

function Layout() {


    return (
        <>
            <CssBaseline />
            <ToolBarActionsProvider>
                <AppToolbar />
                <Outlet />
            </ToolBarActionsProvider>
            <AppBottomNavigation />
        </>
    );
}

export default Layout;
