import * as React from "react";
import {
    AppBar,
    AppBarProps,
    Box,
    IconButton,
    Paper,
    Toolbar,
    Tooltip,
    Typography,
} from "@mui/material";
import {blueGrey} from "@mui/material/colors";
import HomeIcon from "@mui/icons-material/Home";
import {useNavigate} from "react-router-dom";
import {ToolBarActionsContext} from "./common/ToolBarActionsProvider";
import {ReactNode, useEffect, useState} from "react";

type AppToolbarProps = Omit<AppBarProps, "children">;

function AppToolbar(props: AppToolbarProps): JSX.Element {
    const ctx = React.useContext(ToolBarActionsContext);
    const [ actionButtons, setActionButtons ] = useState<ReactNode[]>();

    useEffect(() => {
        if (ctx?.state.actions) {
            setActionButtons(ctx.state.actions);
        }
    }, [ctx?.state.actions])

    const navigate = useNavigate();

    return (
        <>
            <Paper sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 2 }} elevation={3}>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                        <Toolbar sx={{color: blueGrey["50"]}}>
                            <Tooltip title="Home">
                                <IconButton sx={{color: blueGrey["50"]}} onClick={() => {
                                    navigate("/");
                                }}>
                                    <HomeIcon />
                                </IconButton>
                            </Tooltip>
                            <Typography variant="h6" component="div" noWrap ml={2} sx={{ flexGrow: 1 }}>
                                Bierfestival Oudenbosch
                            </Typography>
                            { actionButtons }
                        </Toolbar>
                    </AppBar>
                </Box>
            </Paper>
            {/* Empty toolbar as hack so next element will show below this component instead of behind */}
            <Toolbar sx={{zIndex: -1}}/>
        </>
    );
}

export { AppToolbar };