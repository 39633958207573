import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {CardActionArea, CardMedia, Stack} from '@mui/material';

import "./BeerCard.css";
import {MouseEventHandler} from "react";
import {IBeerData} from "../../types/beer.type";

export default function BeerCard(props: BeerCardProps) {
    let logoUrl = `https://d1g45h2zlkdrpz.cloudfront.net/images/${props.beer.image}`;
    return (
        <Card sx={{ minWidth: 290, maxWidth: 590, padding: {xs: 2, md: 2} }} elevation={5}>
            <CardActionArea onClick={props.onClick} sx={{ display: "flex", flexDirection: "row" }}>
                <CardMedia
                    component="img"
                    className="beerCardImage"
                    sx={{ width: {xs: 75, md: 100}, height: {xs: 75, md: 100 }}}
                    image={logoUrl}
                    alt={props.beer.name}
                />
                <CardContent sx={{flexGrow: 1, padding: 0}}>
                    <Stack className="beerCardInfo">
                        <Typography variant="h6" component="span">
                            {props.beer.name}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                            {props.beer.breweryName} - {props.beer.type}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                            ABV: {props.beer.abv}%&nbsp;&nbsp;&nbsp;{props.beer.ibu > 0 ? "IBU: " + props.beer.ibu : null}
                        </Typography>
                    </Stack>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

interface BeerCardProps {
    beer: IBeerData,
    onClick: MouseEventHandler;
}
