import React, {PropsWithChildren} from 'react';
import {Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import theme from "../../../theme";

export default function BeersFilterComponent(props: BeersFilterComponentProps) {
    return (
        <Container disableGutters>
            <Container sx={{height: 40, width: "100%"}} style={{backgroundColor: "lightgrey"}}>
                <Typography variant={"subtitle1"} color={theme.palette.text.primary} pt={1}>
                    {props.title}
                </Typography>
            </Container>
            <Container sx={{minHeight: 50, width: "100%", pt: 2, pb: 2}} >
                {props.children}
            </Container>
        </Container>
    );
}

interface BeersFilterComponentProps extends PropsWithChildren {
    title: String;
}