import { createTheme } from '@mui/material/styles';
import {blueGrey, red} from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
    palette: {
        text: {
            primary: blueGrey["500"],
            secondary: blueGrey["300"],
        },
        primary: {
            main: '#fbaf32',
        },
        secondary: {
            // main: '#fcc947',
            main: '#fcf9d5',
        },
        background: {
            default: '#fcf9d5',
            paper: '#fff',
        },
        error: {
            main: red.A400,
        },
    },
});

export default theme;