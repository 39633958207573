import { useEffect } from "react";
import { useNavigate, useLocation } from 'react-router';
import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

type LocationProps = {
    state: {
        from: Location;
    };
};

const formFields = {
    signUp: {
        family_name: {
            order: 1
        },
        password: {
            order: 2
        },
        confirm_password: {
            order: 3
        }
    },
}

export function Login() {
    const { route } = useAuthenticator((context) => [context.route]);
    const location = useLocation() as unknown as LocationProps;
    const navigate = useNavigate();

    let from = location.state?.from?.pathname || '/';
    useEffect(() => {
        if (route === 'authenticated') {
            navigate(from, { replace: true });
        }
    }, [route, navigate, from]);
    return (
        <View className="auth-wrapper">
            <Authenticator socialProviders={['google']} formFields={formFields}></Authenticator>
        </View>
    );
}

