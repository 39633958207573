import React, {lazy, Suspense} from 'react';
import {styled} from '@mui/material/styles';
import {Alert, Box, CircularProgress, useMediaQuery, useTheme} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import BeerCard from "./BeerCard";
import {LoadingSkeleton} from "../common/LoadingSkeleton";

import {BeerStyle, IBeersFilter, Sorting, useBeers} from "../../hooks/useBeers";
import BeersFilter from "./filtering/BeersFilter";

const BeerDetailDialog = lazy(() => import("./BeerDetailDialog"));

const Item = styled(BeerCard)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function buildInitialBeersFilter(): IBeersFilter {
    let initialFilter = {
        sorting: Sorting.brewery
    } as IBeersFilter;

    let beerTypes: string[] = [];
    for (let style in BeerStyle) {
        beerTypes.push(style);
    }
    initialFilter.beerTypes = beerTypes;
    initialFilter.abvSelectedValues = [0.0, 15.0];
    initialFilter.ibuSelectedValues = [0, 100];
    initialFilter.ignoredBreweries = [];

    return initialFilter;
}

export default function BeersOverview() {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [beersFilter, setBeersFilter] = React.useState(buildInitialBeersFilter());
    const [{beersList, loading, error}] = useBeers(beersFilter);

    const [openBeerDetailDialog, setOpenBeerDetailDialog] = React.useState(false);
    const [selectedBeer, setSelectedBeer] = React.useState<string | undefined>(undefined);

    const handleClickOpenBeerDetails = (beerId: string) => {
        setSelectedBeer(beerId);
        setOpenBeerDetailDialog(true);
    };

    const handleCloseBeerDetails = () => {
        setOpenBeerDetailDialog(false);
        // setSelectedBrewery(undefined);
    };

    return (
        <Box sx={{flexGrow: 1}} p={2} mb={8}>
            {loading ? <CircularProgress/> : (
                <>
                    <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 1, sm: 2, lg: 3}}>
                        {beersList?.beers?.map((beer, i) => (
                            <Grid xs={1} key={i}>
                                <Item beer={beer} onClick={() => handleClickOpenBeerDetails(beer.id)}/>
                            </Grid>
                        ))}
                    </Grid>
                    <Suspense fallback={<LoadingSkeleton/>}>
                        {openBeerDetailDialog && selectedBeer
                            ? <BeerDetailDialog beerId={selectedBeer} onClose={handleCloseBeerDetails} open/>
                            : null}
                    </Suspense>
                </>
            )}
            <BeersFilter filter={beersFilter} setFilter={setBeersFilter} width={fullScreen ? 340 : 490} />
            {
                error ? (
                    <Alert severity="error">{error.message}</Alert>
                ) : null
            }
        </Box>
    );
}
