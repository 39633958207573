import React, {useState} from "react"

export type ToolBarActionsState = {
    actions: JSX.Element[];
};

export const initialState: ToolBarActionsState = {
    actions: [],
};

type ToolBarActionsContextValue = {
    state: ToolBarActionsState;
    setState: (newState: ToolBarActionsState) => void;
};

export const ToolBarActionsContext = React.createContext<ToolBarActionsContextValue | undefined>(undefined);

// @ts-ignore
export const ToolBarActionsProvider = ({children}) => {
    const [state, setState] = useState<ToolBarActionsState>(initialState);

    return (
        <ToolBarActionsContext.Provider value={{ state, setState }}>
            { children }
        </ToolBarActionsContext.Provider>
    )
}

