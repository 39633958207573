import * as React from 'react';
// import Typography from '@mui/material/Typography';
import {Container} from '@mui/material';
import "./Welcome.css";

export default function Welcome() {
    return (
        <Container className={"content"} sx={{pt: 3 }} disableGutters>
            <img className={"image"} src={"/bierfestivaloudenbosch-glas.jpeg"} alt={"Bierfestival Oudenbosch beer glass"}/>
        </Container>
    );
}
