import React from 'react';
import {Chip, ChipProps} from "@mui/material";
import SelectIcon from "@mui/icons-material/Check";
import theme from "../../../theme";

export default function SelectChip(props: SelectChipProps) {
    return (
        <Chip
            label={props.label}
            color={props.selected ? "primary" : "secondary"}
            onClick={props.onClick}
            icon={props.selected ? <SelectIcon style={{color: theme.palette.secondary.main}}/> : undefined}
        />
    );
}

interface SelectChipProps extends ChipProps {
    selected: boolean;
}