import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {CardActionArea, Stack, useMediaQuery} from '@mui/material';
import {IBreweryData} from "../../types/brewery.type";

import "./BreweryCard.css";
import {MouseEventHandler} from "react";
import {Image} from "mui-image";
import theme from "../../theme";

export default function BreweryCard(props: BreweryCardProps) {
    let logoUrl = `https://d1g45h2zlkdrpz.cloudfront.net/images/${props.brewery.image}`;
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Card sx={{ minWidth: 290, maxWidth: 590, padding: {xs: 1, md: 2} }} elevation={5}>
            <CardActionArea onClick={props.onClick} sx={{ display: "flex", flexDirection: "row" }}>
                <Image
                    src={logoUrl}
                    className="breweryCardImage"
                    width={smallScreen ? 75 : 100}
                    height={smallScreen ? 75 : 100}
                    alt={props.brewery.name}
                    style={{objectFit: "contain"}}
                />
                <CardContent sx={{flexGrow: 1, padding: 0, pl: 3}} >
                    <Stack className="breweryCardInfo">
                        <Typography variant="h6" component="span">
                            {props.brewery.name}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                            {props.brewery.city}
                        </Typography>
                    </Stack>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

interface BreweryCardProps {
    brewery: IBreweryData,
    onClick: MouseEventHandler;
}
