import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import BreweriesIcon from '@mui/icons-material/CoPresent';
import BeersIcon from '@mui/icons-material/SportsBar';
// import MyListIcon from '@mui/icons-material/StarHalf';
import Paper from "@mui/material/Paper";
import {
    Link as RouterLink,
    LinkProps as RouterLinkProps,
} from 'react-router-dom';

const LinkBehaviorBreweries = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>(
    (props, ref) => (
        <RouterLink
            ref={ref}
            to="/breweries"
            {...props}
        />
    ),
);

const LinkBehaviorBeers = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>(
    (props, ref) => (
        <RouterLink
            ref={ref}
            to="/beers"
            {...props}
        />
    ),
);

export default function AppBottomNavigation() {
    const [value, setValue] = React.useState(0);

    return (
        <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 2}} elevation={3}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                sx={{
                    ".MuiBottomNavigationAction-root svg, .MuiBottomNavigationAction-label": {
                        color: "background.paper",
                    },
                    ".MuiBottomNavigationAction-root.Mui-selected svg, .MuiBottomNavigationAction-label.Mui-selected": {
                        color: "background.default"
                    },
                    bgcolor: "primary.main"
                }}
            >
                <BottomNavigationAction sx={{color: "background.default"}} label="De Brouwers" icon={<BreweriesIcon/>} component={LinkBehaviorBreweries}/>
                <BottomNavigationAction sx={{color: "background.default"}} label="De Bieren" icon={<BeersIcon/>} component={LinkBehaviorBeers}/>
                {/*<BottomNavigationAction sx={{color: "background.default"}} label="Mijn Lijst" icon={<MyListIcon/>} component={LinkBehaviorBreweries}/>*/}
            </BottomNavigation>
        </Paper>
    );
}
