import React, {useEffect} from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import Layout from "./components/Layout";
import Welcome from "./components/common/Welcome";
import BreweriesOverview from "./components/breweries/BreweriesOverview";
import BeersOverview from "./components/beers/BeersOverview";
import {Login} from "./components/auth/Login";


import { Amplify } from "aws-amplify";
import awsExports from './aws-exports';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import './App.css';
import theme from "./theme";

function App() {
  useEffect(() => {
    Amplify.configure({
      Auth: {
        mandatorySignIn: false,
        region: awsExports.Auth.region,
        userPoolId: awsExports.Auth.userPoolId,
        userPoolWebClientId: awsExports.Auth.userPoolWebClientId,
        // identityPoolId: "eu-west-1:175a6557-0821-4c2f-b04c-637e7fd34337",
      },
      // Storage: {
      //   AWSS3: {
      //     bucket: 'bierfestivaloudenbosch-images-bucket', //REQUIRED -  Amazon S3 bucket name
      //     region: 'eu-west-1', //OPTIONAL -  Amazon service region
      //   }
      // }
    })
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {/*<Authenticator socialProviders={['google']} variation="modal">*/}
      {/*  {({ signOut, user }) => (*/}
      {/*      <div>*/}
      {/*        <p>Welcome {user?.username}</p>*/}
      {/*        <button onClick={signOut}>Sign out</button>*/}
      {/*      </div>*/}
      {/*  )}*/}
      {/*</Authenticator>*/}
      <Authenticator.Provider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="" element={<Welcome />}/>
              <Route path="login" element={<Login />}/>
              <Route path="breweries" element={<BreweriesOverview />} />
              <Route path="beers" element={<BeersOverview />} />
              <Route
                  path="*"
                  element={
                    <main style={{ padding: "1rem" }}>
                      <p>There's nothing here!</p>
                    </main>
                  }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </Authenticator.Provider>
    </ThemeProvider>
  );
}

export default App;
