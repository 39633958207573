import React, {useEffect} from 'react';
import {BeerStyle, beerStyleToString, IBeersFilter, Sorting} from "../../../hooks/useBeers";
import {Box, Drawer, Slider} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import {initialState, ToolBarActionsContext} from "../../common/ToolBarActionsProvider";
import IconButton from "@mui/material/IconButton";
import TuneIcon from "@mui/icons-material/Tune";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import theme from "../../../theme";
import BeersFilterComponent from "./BeersFilterComponent";
import Grid from "@mui/material/Unstable_Grid2";
import SelectChip from "./SelectChip";

function getAllBeerStyles(): BeerStyle[] {
    let result: BeerStyle[] = [];
    for (let style in BeerStyle) {
        result.push(style as BeerStyle);
    }
    return result;
}

export default function BeersFilter(props: BeersFilterProps) {
    const [open, setOpen] = React.useState(false);

    const beerStyles = getAllBeerStyles();

    const toggleDrawer = (newOpen: boolean) => {
        setOpen(newOpen);
    };

    const toolBarActionsCtx = React.useContext(ToolBarActionsContext);
    useEffect(() => {
        toolBarActionsCtx?.setState({
            actions: ([
                <IconButton
                    color="inherit"
                    aria-label="open filters"
                    edge="start"
                    onClick={() => toggleDrawer(!open)}
                    sx={{mr: 2}}
                    key={"beersFiltersToggle"}
                >
                    <TuneIcon/>
                </IconButton>
            ])
        });

        return () => {
            toolBarActionsCtx?.setState(initialState)
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleSortClick = (sorting: Sorting) => {
        props.setFilter({...props.filter, sorting: sorting})
    };

    const isBeerStyleSelected = (style: BeerStyle): boolean => {
        let isSelected = false;
        props.filter.beerTypes.forEach((type) => {
            if (type === style.valueOf()) {
                isSelected = true;
                return true;
            }
        })
        return isSelected;
    }

    const handleFilterByStyleClick = (style: BeerStyle) => {
        let index = props.filter.beerTypes.indexOf(style.toString());
        let newTypes = props.filter.beerTypes;

        if (index < 0) {
            newTypes.push(style.toString());
            newTypes.sort((a, b) => {
                return a.localeCompare(b);
            });
        } else {
            newTypes = newTypes.slice(0, index);
            newTypes.push(...props.filter.beerTypes.slice(index + 1));
        }
        props.setFilter({...props.filter, beerTypes: newTypes});
    };

    const handleFilterByABV = (event: Event, newValues: number | number[]) => {
        props.setFilter({...props.filter, abvSelectedValues: newValues as number[]})
    };

    const handleFilterByIBU = (event: Event, newValues: number | number[]) => {
        props.setFilter({...props.filter, ibuSelectedValues: newValues as number[]})
    };

    return (
        <Box sx={{overflow: 'auto'}}>
            <Drawer
                variant="temporary"
                anchor={"right"}
                open={open}
                onClose={() => toggleDrawer(false)}
                // onOpen={toggleDrawer(true)}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: props?.width},
                    flexShrink: 0,
                    zIndex: 5,
                }}
            >
                <AppBar position="sticky">
                    <Toolbar color={theme.palette.background.default}>
                        <Typography variant="h6" component="div" noWrap ml={2} sx={{flexGrow: 1}}
                                    color={theme.palette.background.default}>
                            Filteren & Sorteren
                        </Typography>
                        <IconButton
                            aria-label="close filters"
                            edge="end"
                            onClick={() => toggleDrawer(false)}
                            sx={{mr: 2, color: theme.palette.background.default}}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <BeersFilterComponent title="Sorteren">
                    <Grid container direction="row" spacing={1}>
                        <Grid>
                            <SelectChip
                                label="Brouwerij"
                                selected={props.filter.sorting === Sorting.brewery}
                                onClick={() => handleSortClick(Sorting.brewery)}
                            />
                        </Grid>
                        <Grid>
                            <SelectChip
                                label="Naam"
                                selected={props.filter.sorting === Sorting.name}
                                onClick={() => handleSortClick(Sorting.name)}
                            />
                        </Grid>
                        <Grid>
                            <SelectChip
                                label="ABV"
                                selected={props.filter.sorting === Sorting.abv}
                                onClick={() => handleSortClick(Sorting.abv)}
                            />
                        </Grid>
                        <Grid>
                            <SelectChip
                                label="IBU"
                                selected={props.filter.sorting === Sorting.ibu}
                                onClick={() => handleSortClick(Sorting.ibu)}
                            />
                        </Grid>
                    </Grid>
                </BeersFilterComponent>
                <BeersFilterComponent title="Stijl(en)">
                    <Grid container direction="row" spacing={1}>
                        {
                            beerStyles.map((style, index) => (
                                <Grid key={"grid" + index}>
                                    <SelectChip
                                        key={"chip" + index}
                                        label={beerStyleToString(style)}
                                        selected={isBeerStyleSelected(style)}
                                        onClick={() => handleFilterByStyleClick(style)}
                                    />
                                </Grid>))
                        }
                    </Grid>
                </BeersFilterComponent>
                <BeersFilterComponent title="ABV (Alcohol percentage)">
                    <Slider
                        getAriaLabel={() => 'ABV (Alcohol percentage)'}
                        value={props.filter.abvSelectedValues}
                        min={0.0}
                        max={15.0}
                        step={0.5}
                        marks
                        onChange={handleFilterByABV}
                        valueLabelDisplay="auto"
                        valueLabelFormat={(value) => { return `${value}%`}}
                        getAriaValueText={(value: number) => { return `${value}%`}}
                    />
                </BeersFilterComponent>
                <BeersFilterComponent title="IBU (Bitterheid)">
                    <Slider
                        getAriaLabel={() => 'IBU (Bitterheid)'}
                        value={props.filter.ibuSelectedValues}
                        min={0}
                        max={100}
                        step={5}
                        marks
                        onChange={handleFilterByIBU}
                        valueLabelDisplay="auto"
                        getAriaValueText={(value: number) => { return `${value}%`}}
                    />
                </BeersFilterComponent>
            </Drawer>
        </Box>
    );
}

interface BeersFilterProps {
    width?: number;
    filter: IBeersFilter
    setFilter: (newFilter: IBeersFilter) => void;
}
