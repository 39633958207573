import useAxios from "axios-hooks";
import {IBeerData} from "../types/beer.type";

export enum Sorting {
    brewery, name, abv, ibu
}

export enum BeerStyle {
    ale = "ale",
    barleyWine = "barleyWine",
    barrelAged = "barrelAged",
    bock = "bock",
    blond = "blond",
    dipa = "dipa",
    dubbel = "dubbel",
    fruit = "fruit",
    ipa = "ipa",
    other = "other",
    quadrupel = "quadrupel",
    session = "session",
    sour = "sour",
    stout = "stout",
    tripel = "tripel",
    weizen = "weizen",
    wit = "wit"
}

export const beerStyleToString = (style: BeerStyle): string => {

    switch (style) {
        case BeerStyle.ale:
            return "Ale"
        case BeerStyle.barleyWine:
            return "Barley Wine";
        case BeerStyle.barrelAged:
            return "Barrel Aged";
        case BeerStyle.bock:
            return "Bock";
        case BeerStyle.blond:
            return "Blond";
        case BeerStyle.dipa:
            return "DIPA";
        case BeerStyle.dubbel:
            return "Dubbel";
        case BeerStyle.fruit:
            return "Fruit";
        case BeerStyle.ipa:
            return "IPA";
        case BeerStyle.other:
            return "Overige";
        case BeerStyle.quadrupel:
            return "Quadrupel";
        case BeerStyle.session:
            return "Session";
        case BeerStyle.sour:
            return "Sour";
        case BeerStyle.stout:
            return "Stout";
        case BeerStyle.tripel:
            return "Tripel";
        case BeerStyle.weizen:
            return "Weizen";
        case BeerStyle.wit:
            return "Wit";
    }
}

export interface IBeersFilter {
    sorting: Sorting
    beerTypes: string[]
    abvSelectedValues: number[];
    ibuSelectedValues: number[];
    ignoredBreweries: string[]
}

export const useBeers = (filter: IBeersFilter | undefined) => {
    const [{data: beersList, loading, error}] = useAxios<IBeerData[]>({
        url: "https://test-api.bierfestivaloudenbosch.nl/beers",
    });

    function sortBeers(beers: IBeerData[] | undefined): IBeerData[] | undefined {
        if (beers === undefined || filter === undefined) {
            return beers
        }

        return beers.sort((a, b) => {
            switch (filter.sorting) {
                case Sorting.abv:
                    return a.abv - b.abv;
                case Sorting.ibu:
                    return a.ibu - b.ibu;
                case Sorting.name:
                    return a.name.localeCompare(b.name);
                default:
                    return a.breweryName.localeCompare(b.breweryName);
            }
        });
    }

    function filterBeers(beers: IBeerData[] | undefined): IBeerData[] | undefined {
        if (beers === undefined || filter === undefined) {
            return beers;
        }

        return beers.filter((beer) => {
            if (beer.beerType === undefined) {
                console.log(`No matchtypes for beer ${beer.name}`);
                return false;
            }

            // Match on Beer Style/Type
            let isMatched = false;
            beer.beerType.forEach((matchType) => {
                if (filter.beerTypes.includes(matchType)) {
                    isMatched = true;
                }
            })
            return isMatched;
        }).filter((beer) => {
            // Match on ABV and IBU
            return beer.abv >= filter.abvSelectedValues[0] && filter.abvSelectedValues[1] >= beer.abv &&
                beer.ibu >= filter.ibuSelectedValues[0] && filter.ibuSelectedValues[1] >= beer.ibu;
        // }).filter((beer) => {
        //     // Match on ignored breweries
        //     return !filter.ignoredBreweries?.includes(beer.breweryName);
        });
    }

    return [{
        beersList: {beers: sortBeers(filterBeers(beersList))},
        loading: loading,
        error: error,
    }];
};

