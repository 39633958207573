import React, {lazy, Suspense} from 'react';
import {styled} from '@mui/material/styles';
import {Alert, Box, CircularProgress} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import useAxios from "axios-hooks";
import {IBreweryData} from "../../types/brewery.type";
import BreweryCard from "./BreweryCard";
import {LoadingSkeleton} from "../common/LoadingSkeleton";

const BreweryDetailDialog = lazy(() => import("./BreweryDetailDialog"));

const Item = styled(BreweryCard)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function BreweriesOverview() {
    const [{data: breweriesList, loading, error}] = useAxios<IBreweryData[]>({
        url: "https://test-api.bierfestivaloudenbosch.nl/breweries",
    });

    const [open, setOpen] = React.useState(false);
    const [selectedBrewery, setSelectedBrewery] = React.useState<string | undefined>(undefined);

    const handleClickOpen = (breweryId: string) => {
        setSelectedBrewery(breweryId);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        // setSelectedBrewery(undefined);
    };

    return (
        <Box sx={{flexGrow: 1}} p={2} mb={8}>
            {loading ? <CircularProgress/> : (
                <>
                    <Grid container spacing={{xs: 2, md: 3, lg: 4}} columns={{xs: 1, sm: 2, lg: 3}}>
                        {breweriesList?.map((brewery, i) => (
                            <Grid xs={1} key={i}>
                                <Item brewery={brewery} onClick={() => handleClickOpen(brewery.id)}/>
                            </Grid>
                        ))}
                    </Grid>
                    <Suspense fallback={<LoadingSkeleton/>}>
                        {open && selectedBrewery
                            ? <BreweryDetailDialog breweryId={selectedBrewery} onClose={handleClose} open/>
                            : null}
                    </Suspense>
                </>
            )}
            {
                error ? (
                    <Alert severity="error">{error.message}</Alert>
                ) : null
            }
        </Box>
    );
}
